import {
  Carousel,
  Image,
  Spin,
  Switch,
  Tooltip,
  Typography,
  message,
} from 'antd';
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { COLORS } from '../../themes/colors';
import { debounce } from 'lodash';

import axios from 'axios';
import {
  ITwitchVideo,
  IYoutubeVideo,
  IVimeoVideo,
  IGoogleDriveVideo,
} from '../../data/intefaces/video.interface';
import { UserContext } from '../../data/userContext';
import {
  isTwitchUrl,
  isYoutubeUrl,
  isVimeoUrl,
  isGoogleDriveUrl,
  roundWithTwoDecimals,
  useTraceUpdate,
  checkFileType,
  completeOnboardingStep,
  getPricingRedirectUrl,
  showGoProButton,
} from '../../utils';
import {
  demoVideoPreview,
  supportedLanguages,
} from '../../constants/content-constants';
import { ReactComponent as IconDesktop } from '../../assets/desktop-icon.svg';
import { ReactComponent as IconMobile } from '../../assets/mobile-icon.svg';
import { ReactComponent as IconStar } from '../../assets/blurred-star.svg';
import { ReactComponent as IconStarNoColor } from '../../assets/icon-star-no-color.svg';
import { ReactComponent as PricingCard } from '../../assets/pricing-card.svg';
import { ReactComponent as IconMagicWand } from '../../assets/magic_wand.svg';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';

import IconClose from '../../assets/modal-close.svg';
import IconCloseCircle from '../../assets/close-circle.svg';
import Blog3 from '../../assets/blog-3.png';
import LayoutDesktop from '../../assets/layout-desktop.png';
import LayoutMobile from '../../assets/layout-mobile.png';
import UploadFromComputerImage from '../../assets/upload-from-computer.png';
import PricingModalImage from '../../assets/pricing-modal-image.png';
import StyleOneGif from '../../assets/style-one.gif';
import StyleTwoGif from '../../assets/style-two.gif';
import StyleThreeGif from '../../assets/style-three.gif';
import StyleFourGif from '../../assets/style-four.gif';
import RightArrow from '../../assets/right-arrow.svg';
import { ReactComponent as DropdownIndicatorIcon } from '../../assets/dropdown-indicator.svg';
import Modal from 'react-modal';
import './UploadSteps.less';
import { LoadingOutlined } from '@ant-design/icons';
import { SubscriptionCard } from '../../pages/Subscriptions/subscription-card';
import Select, { DropdownIndicatorProps, components } from 'react-select';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { postAudit } from '../../services/audit.service';
import { StyleStep } from './StyleStep';
import { AIGeneratorTextArea } from '../../pages/Editor/BRollTab/BRollTabInput';
import { PricingModal } from '../pricing-modal/PricingModal';
import { OnboardingContext } from '../../data/onboardingContext';
import { IEditorTemplate } from '../../data/intefaces/streamer.interface';

Modal.setAppElement('#root');

export interface IUploadStepsProps {
  url: string;
  setLoading: (loading: boolean) => void;
  setDisableMakeClips: (disabled: boolean) => void;
  setYoutubeVideoDetails: (details: any) => void;
  demoMode?: boolean;
  isOpen: boolean;
  handleTranscribe: (
    userTemplate: IEditorTemplate,
    autoZoomEnabled?: boolean,
    clipLength?: number,
    stylePreset?: any,
    layoutType?: 'mobile' | 'desktop',
    fileType?: 'video' | 'audio',
    magicLookText?: string,
    enableFaceTracking?: boolean,
    language?: string | null,
    featureSliders?: any,
  ) => void;
  handleExtractHighlights: (
    userTemplate: IEditorTemplate,
    autoZoomEnabled?: boolean,
    clipLength?: number,
    stylePreset?: any,
    layoutType?: 'mobile' | 'desktop',
    fileType?: 'video' | 'audio',
    magicLookText?: string,
    enableFaceTracking?: boolean,
    language?: string | null,
    featureSliders?: any,
  ) => void;
  handleCloseModal: () => void;
  clearUrlField: () => void;
  handleShowYoutubeLimit: () => void;
  handleCloseYoutubeLimit: () => void;
  handleVideoDurationLimit: (custom?: string) => void;
  uploadedVideoDetails: any;
  mode: 'highlights' | 'transcribe';
}

const highlightSteps = [
  {
    id: 'video',
    name: 'Video',
  },
  // {
  //   id: 'layout',
  //   name: 'Layout',
  // },
  {
    id: 'style',
    name: 'Style',
  },
  {
    id: 'finalTune',
    name: 'Final Tune',
  },
];

const transcribeSteps = [
  {
    id: 'video',
    name: 'Video',
  },
  // {
  //   id: 'layout',
  //   name: 'Layout',
  // },
  {
    id: 'style',
    name: 'Style',
  },
  {
    id: 'finalTune',
    name: 'Final Tune',
  },
];

const stylePresets = [
  {
    id: 2,
    label: 'Captions Style',
    name: 'Sentence',
    availableToAll: true,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'none',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 1,
    label: 'Captions Style',
    name: 'Word by Word',
    availableToAll: true,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'none',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
];

{
  /* REMOVE THIS OBJECT BELOW AND UNCOMMENT ABOVE: ANIMATED CAPTIONS IN DROPDOWN */
}
// const stylePresets = [
//   {
//     id: 1,
//     label: 'Captions Style',
//     name: 'Word by Word',
//     availableToAll: true,
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'true',
//       captions_animation_type: 'none'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
//   {
//     id: 2,
//     label: 'Captions Style',
//     name: 'Sentence',
//     availableToAll: true,
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'false',
//       captions_animation_type: 'none'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
//   {
//     id: 3,
//     label: 'Captions Style',
//     name: 'Background Pop',
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'true',
//       captions_animation_type: 'wword'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
//   {
//     id: 4,
//     label: 'Captions Style',
//     name: 'Bounce',
//     style: {
//       background: '#000000',
//       color: '#FFFFFF',
//       textShadow: 'Heavy',
//       fontWeight: 'normal',
//       textTransform: 'uppercase',
//       fontSize: 'Big',
//       isHighlighted: false,
//       alignItems: 'Bottom',
//       fontFamily: 'Impact',
//       wordLevel: 'false',
//       captions_animation_type: 'bounce'
//     },
//     styleForFrontend: {
//       color: '#FFFFFF',
//     }
//   },
// ];

const additionalStyles = [
  {
    id: 4,
    label: 'Bounce',
    name: 'Bounce',
    animationName: 'bounce-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'bounce',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 5,
    label: 'Karaoke',
    name: 'Karaoke',
    animationName: 'karaoke-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'karaoke',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 3,
    label: 'Background Pop',
    name: 'Background Pop',
    animationName: 'wword-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'wword',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 6,
    label: 'Blossom',
    name: 'Blossom',
    animationName: 'blossom',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'blossom',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },

  {
    id: 7,
    label: 'Sunshine',
    name: 'Sunshine',
    animationName: 'sunshine',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'sunshine',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 8,
    label: 'Emerald',
    name: 'Emerald',
    animationName: 'emerald',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'emerald',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
  {
    id: 8,
    label: 'Slide-Left',
    name: 'Slide-Left',
    animationName: 'slide-left-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'slide-left',
    },
    styleForFrontend: {
      color: '#FFFFFF',
    },
  },
];

const lengthOptions = [
  { value: -1, label: 'Auto' },
  { value: 45, label: '40 - 50 sec' },
  { value: 55, label: '50 - 60 sec' },
  { value: 65, label: '60 - 70 sec' },
  { value: 75, label: '70 - 80 sec' },
  { value: 85, label: '80 - 90 sec' },
];

export const UploadSteps = memo(
  ({
    isOpen,
    url,
    setLoading,
    handleShowYoutubeLimit,
    setDisableMakeClips,
    handleCloseYoutubeLimit,
    setYoutubeVideoDetails,
    demoMode = false,
    handleTranscribe,
    handleExtractHighlights,
    mode,
    handleCloseModal,
    uploadedVideoDetails,
    clearUrlField,
    handleVideoDurationLimit,
  }: IUploadStepsProps) => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [videoDetails, setVideoDetails] = useState<IYoutubeVideo | null>(
      null,
    );
    const [invalidGoogleDriveLink, setinValidGoogleDriveLink] = useState(false);
    const [height, setHeight] = useState(0);
    const [selectedLayoutType, setSelectedLayoutType] = useState<
      'desktop' | 'mobile'
    >('desktop');
    const [selectedStylePreset, setSelectedStylePreset] = useState(
      stylePresets[0],
    );
    const [selectedStyleInDropdown, setSelectedStyleInDropdown] = useState(
      additionalStyles[4],
    );
    const [selectedLength, setSelectedLength] = useState(-1); // Auto
    const [showPricingModal, setShowPricingModal] = useState(false);
    const [loadingVideoDetails, setLoadingVideoDetails] = useState(false);
    const [currentFileType, setCurrentFileType] = useState<'video' | 'audio'>(
      'video',
    );
    const [magicLookText, setMagicLookText] = useState('');
    const [faceTrackingEnabled, setFaceTrackingEnabled] = useState(true);
    const [autoZoomEnabled, setAutoZoomEnabled] = useState(true);
    const [selectedLanguage, setSelectedLanguage] = useState(
      supportedLanguages[0],
    );
    const { width } = useWindowDimensions();
    const isMobile = width < 920;
    const localStorageFaceTrackingEnabled = localStorage.getItem(
      'faceTrackingEnabledPreference',
    );
    const localStorageAutoZoomEnabled = localStorage.getItem(
      'autoZoomEnabledPreference',
    );
    const onboardingContext = useContext(OnboardingContext);

    const [messageApi, contextHolder] = message.useMessage();
    const userContext = useContext(UserContext);
    const usedMinutes = userContext?.user?.used_upload_minutes;
    const totalMinutes = userContext?.user?.total_upload_minutes;
    const hasSubscription =
      userContext?.user?.subscriptions && userContext?.user?.subscriptions[0];
    const ref = useRef(null);
    const navigate = useNavigate();
    const SecondsToISO8601Duration = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `PT${minutes}M${remainingSeconds}S`;
    };
    const editorTemplate = userContext?.user?.editor_template;
    const showTemplateStep = Boolean(
      editorTemplate && editorTemplate?.subtitles_settings?.alignItems,
    );

    const isGoogleUser = !userContext?.user?.twitch_id;

    const isFaceTrackingDisabledByPermissions = Boolean(
      !userContext?.user?.permissions ||
        !userContext?.user.permissions?.hd_enabled,
    );

    const steps = mode === 'highlights' ? highlightSteps : transcribeSteps;

    useEffect(() => {
      if (localStorageFaceTrackingEnabled) {
        const enabled = JSON.parse(localStorageFaceTrackingEnabled);
        setFaceTrackingEnabled(Boolean(enabled));
      }
    }, []);
    useEffect(() => {
      if (localStorageAutoZoomEnabled) {
        const enabled = JSON.parse(localStorageAutoZoomEnabled);
        setAutoZoomEnabled(Boolean(enabled));
      }
    }, []);

    useEffect(() => {
      completeOnboardingStep('upload_video', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);
    }, []);

    useEffect(() => {
      if (isFaceTrackingDisabledByPermissions) {
        setFaceTrackingEnabled(false);
      }
    }, [isFaceTrackingDisabledByPermissions]);

    useEffect(() => {
      setLoadingVideoDetails(true);

      setTimeout(() => {
        setLoadingVideoDetails(false);
      }, 2000);
    }, []);

    const transcribeVideoLimitSeconds = userContext?.user?.permissions
      ?.video_transcribe_limit
      ? userContext?.user?.permissions?.video_transcribe_limit * 60
      : 120;

    const youtubeLimitForFreeUsers = 900;

    useEffect(() => {
      if (mode === 'transcribe') {
        // Limit Transcribe video duration
        if (uploadedVideoDetails?.videoDuration > transcribeVideoLimitSeconds) {
          handleVideoDurationLimit();
        }
      }
      // Newcomers //
      // else if (isYoutubeUrl(url) && showGoProButton(userContext?.user)) {
      //   if (uploadedVideoDetails?.videoDuration > youtubeLimitForFreeUsers) {
      //     handleShowYoutubeLimit();
      //   }
      //   else {
      //     handleCloseYoutubeLimit();
      //   }
      // } else {
      //   handleCloseYoutubeLimit();
      // }
    }, [uploadedVideoDetails]);

    const fetchVideoPreviewDetails = async () => {
      setLoadingVideoDetails(true);

      try {
        if (url) {
          const validYoutubeUrl = isYoutubeUrl(url);
          const validTwitchUrl = isTwitchUrl(url);
          const validVimeoUrl = isVimeoUrl(url);
          const validGoogleDriveUrl = isGoogleDriveUrl(url); // Add a function to check if it's a valid Google Drive URL

          if (validTwitchUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/twitch-clip?url=${url}`,
              );
              const video: ITwitchVideo = response?.data;
              if (video) {
                const details = {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  contentDetails: {
                    duration: SecondsToISO8601Duration(video.duration),
                    videoDuration: video.duration,
                  },
                  snippet: {
                    thumbnails: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxres: {
                        url: video.thumbnail_url,
                      },
                    },
                    channelTitle: video.creator_name,
                    title: video.title,
                  },
                };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setVideoDetails(details);
                setYoutubeVideoDetails(details);
                setDisableMakeClips(false);
              } else {
                // messageApi.error('Video is not valid.', 5);
                // setDisableMakeClips(true);
                setDisableMakeClips(false);
              }
            } catch (error) {
              console.log('Error fetching video preview', error);
              // setDisableMakeClips(true);
              setDisableMakeClips(false);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else if (validYoutubeUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/youtube-video?url=${url}`,
              );
              const video: IYoutubeVideo = response?.data;
              if (video && video.contentDetails) {
                setVideoDetails(video);
                setYoutubeVideoDetails(video);
                setDisableMakeClips(false);
              } else {
                // messageApi.error('Video is not valid.', 5);
                // setDisableMakeClips(true);
                setDisableMakeClips(false);
              }
            } catch (error) {
              console.log('Error fetching video preview', error);
              postAudit({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                user_action: `Error fetching youtube video details while uploading. URL: ${url}`,
                user_id: userContext?.user?.id,
              });
              // setDisableMakeClips(true);
              setDisableMakeClips(false);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else if (validVimeoUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/vimeo-video?url=${url}`,
              );
              const video: IVimeoVideo = response?.data;
              if (video) {
                const details = {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  contentDetails: {
                    duration: video.duration
                      ? SecondsToISO8601Duration(video.duration)
                      : ' ',
                    videoDuration: video.duration || ' ',
                  },
                  snippet: {
                    thumbnails: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxres: {
                        url: video.pictures?.base_link || ' ',
                      },
                    },
                    channelTitle: video.app?.name || ' ',
                    title: video.user?.name || ' ',
                  },
                };
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setVideoDetails(details); // Set the Vimeo video details here
                setYoutubeVideoDetails(details); // Set the Vimeo video details here
                setDisableMakeClips(false);
              }
            } catch (error) {
              console.log('Error fetching Vimeo video preview', error);
              setDisableMakeClips(false);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else if (validGoogleDriveUrl) {
            setLoading(true);
            setLoadingVideoDetails(true);
            setVideoDetails(null);
            try {
              const response = await axios.get(
                `/streamer/google-drive-video?url=${url}`,
              );
              const video: IGoogleDriveVideo = response?.data;
              const fileExtension = video?.fileExtension;

              const fileType = checkFileType(fileExtension);
              setCurrentFileType(fileType);
              if (video) {
                const details = {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  contentDetails: {
                    duration: video?.videoMediaMetadata.durationMillis
                      ? SecondsToISO8601Duration(
                          parseInt(
                            video.videoMediaMetadata.durationMillis,
                            10,
                          ) / 1000,
                        )
                      : ' ',
                    videoDuration: Math.floor(
                      parseInt(video.videoMediaMetadata.durationMillis, 10) /
                        1000,
                    ),
                  },
                  snippet: {
                    thumbnails: {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      maxres: {
                        url: video?.thumbnailLink,
                      },
                    },
                    channelTitle: video?.title,
                    title: video?.title,
                  },
                };
                // Update with setting Google Drive video details
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setVideoDetails(details);
                setYoutubeVideoDetails(details); // Set the Vimeo video details here
                setDisableMakeClips(false);
              }
            } catch (error) {
              setinValidGoogleDriveLink(true);
              console.log('Error fetching Google Drive video preview', error);
            }
            setLoading(false);
            setLoadingVideoDetails(false);
          } else {
            messageApi.error(
              'Only YouTube, Twitch, Vimeo, and Google Drive links are supported.',
              5,
            );
            setDisableMakeClips(true);
          }
        } else {
          setDisableMakeClips(true);
        }
      } catch (error) {
        console.error('Error loading preview', error);
        setLoading(false);
        setLoadingVideoDetails(false);
      }
    };

    // const debouncedFetchVideoPreviewDetails = debounce(fetchVideoPreviewDetails, 500);

    useEffect(() => {
      if (
        isYoutubeUrl(url) ||
        isVimeoUrl(url) ||
        isGoogleDriveUrl(url) ||
        isTwitchUrl(url)
      ) {
        const current: any = ref.current;
        if (current) {
          setHeight(current?.clientHeight);
        }
      }
    });

    useEffect(() => {
      setVideoDetails(null);
    }, []);

    useEffect(() => {
      if (
        url &&
        (isYoutubeUrl(url) ||
          isVimeoUrl(url) ||
          isGoogleDriveUrl(url) ||
          isTwitchUrl(url)) &&
        !demoMode
      ) {
        if (url) {
          fetchVideoPreviewDetails();
        } else {
          setVideoDetails(null);
        }
      }
    }, [url, isOpen]);

    const getVideoDuration = () => {
      if (videoDetails) {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = videoDetails.contentDetails.duration.match(regex);
        if (matches) {
          const hours = parseInt(matches[1]) || 0;
          const minutes = parseInt(matches[2]) || 0;
          const seconds = parseInt(matches[3]) || 0;
          const totalMinutes = hours * 60 + minutes + seconds / 100;

          return totalMinutes;
        }
      } else if (uploadedVideoDetails?.videoDuration) {
        return Number((uploadedVideoDetails?.videoDuration / 60).toFixed(2)); // Computer video
      } else return null;
    };

    const getRemainingMinutes = () => {
      if (totalMinutes && usedMinutes) {
        return totalMinutes - usedMinutes;
      } else if (totalMinutes && usedMinutes === 0) {
        return totalMinutes;
      }
      return null;
    };

    const getCreditsAfter = () => {
      return Number((getRemainingMinutes()! - getVideoDuration()!).toFixed(2));
    };

    const checkIfCreditsAreInsufficient = () => {
      const remainingMinutes = getRemainingMinutes();
      const videoDuration = getVideoDuration();
      if (remainingMinutes && videoDuration) {
        if (remainingMinutes < videoDuration) {
          return true;
        } else return false;
      } else {
        return false;
      }
    };

    const roundEstimatedDuration = (durationMinutes: number) => {
      const rounded = Math.ceil(durationMinutes / 3);
      if (rounded === 0) return 1;
      return rounded;
    };
    const gifUrls = [StyleOneGif, StyleTwoGif, StyleThreeGif, StyleFourGif];
    const styles = useMemo(() => {
      return {
        container: {
          padding: 20,
          display: 'flex',
          backgroundColor: COLORS.GRAY_BACKGROUND,
          borderRadius: '0px 0px 8px 8px',
          gap: '26px',
          maxWidth: 1070,
          width: 'fit-content',
          margin: '0 auto',
          marginBottom: 28,
          marginTop: -10,
        },
        contentWrapper: {
          display: 'flex',
          flexDirection: 'column',
          gap: '13px',
          minWidth: 425,
          maxWidth: 425,
          height: 'fit-content',
        },
        channelName: {
          fontSize: 20,
          fontWeight: 400,
          color: COLORS.SUBTEXT_GRAY_LIGHT,
          lineHeight: '29px',
        },
        item: {
          display: 'flex',
        },
        propertyName: {
          fontSize: 16,
          fontWeight: 400,
          color: COLORS.SUBTEXT_GRAY_LIGHT,
          lineHeight: '28px',
          minWidth: 185,
        },
        propertyValue: {
          fontSize: 16,
          fontWeight: 400,
          color: COLORS.GRAY_DISABLED,
          lineHeight: '28px',
        },
        chipPropertyValue: {
          fontSize: 18,
          fontWeight: 400,
          color: COLORS.WHITE,
          backgroundColor: COLORS.CHIP_BACKGROUND,
          padding: '3px 6px',
          lineHeight: '28px',
          borderRadius: 5,
        },
        creditsText: {
          fontSize: 14,
          fontWeight: 400,
          color: COLORS.SUBTEXT_GRAY_LIGHT,
          lineHeight: '28px',
          width: 185,
        },
        invalidGoogleLink: {
          fontSize: 16,
          fontWeight: 'bold',
          color: '#ffd700',
          textDecoration: 'underline',
        },
      } as const;
    }, []);

    const renderImage = () => {
      return (
        videoDetails?.snippet?.thumbnails?.maxres?.url ||
        videoDetails?.snippet?.thumbnails?.standard?.url ||
        videoDetails?.snippet?.thumbnails?.default?.url
      );
    };

    const renderChannel = () => {
      if (demoMode) return demoVideoPreview.channelName;
      else return videoDetails?.snippet?.channelTitle;
    };

    const renderVideoName = () => {
      if (demoMode) return demoVideoPreview.videoName;
      else {
        return videoDetails?.snippet?.title;
      }
    };

    const renderViews = () => {
      if (demoMode) return demoVideoPreview.viewsCount;
      else return Number(videoDetails?.statistics?.viewCount).toLocaleString();
    };

    const renderDuration = () => {
      if (demoMode) return demoVideoPreview.duration;
      if (getVideoDuration()) return getVideoDuration();
      else return (uploadedVideoDetails?.videoDuration / 60).toFixed(2);
    };

    const renderViralClipsEstimation = () => {
      if (demoMode) return demoVideoPreview.viralClipsEstimation;
      else {
        const duration = getVideoDuration();
        if (typeof duration === 'number') {
          const roundedDuration = roundEstimatedDuration(duration);
          return `${roundedDuration} - ${roundedDuration * 3} Viral Clips`;
        }
      }
    };

    const handlePrevStep = () => {
      setCurrentStepIndex((step) => step - 1);
    };

    const handleNextStep = () => {
      // Template step
      if (currentStepIndex === 1) {
        // if (showTemplateStep) {
        saveUpdatedTemplate();
        // }
      }
      setCurrentStepIndex((step) => step + 1);
    };

    const saveUpdatedTemplate = () => {
      const data = {
        editor_template: editorTemplate,
      };
      axios
        .put('/streamer/update-user-editor-template', data)
        .catch((error: { response: any }) => {
          if (error.response) {
            console.log(error.response);
          }
        });
    };

    const resetAllChosenValues = () => {
      setCurrentStepIndex(0);
      setSelectedLayoutType('desktop');
      setSelectedLength(-1);
      setSelectedStylePreset(stylePresets[0]);
    };

    const handleGenerateClips = () => {
      if (!isFaceTrackingDisabledByPermissions) {
        localStorage.setItem(
          'faceTrackingEnabledPreference',
          JSON.stringify(faceTrackingEnabled),
        );
        localStorage.setItem(
          'autoZoomEnabledPreference',
          JSON.stringify(autoZoomEnabled),
        );
      }
      if (getCreditsAfter() < 0) {
        messageApi.error('You do not have enough credits.', 5);
        return null;
      }
      // ---- Save Template ----
      const editorTemplate = userContext?.user?.editor_template;
      const data = {
        editor_template: editorTemplate,
      };

      console.log('editorTemplate', editorTemplate);

      axios
        .put('/streamer/update-user-editor-template', data)
        .catch((error: { response: any }) => {
          if (error.response) {
            console.log(error.response);
          }
        });
      // ---------
      completeOnboardingStep('generate_clips', userContext?.user);
      onboardingContext?.setRerender(onboardingContext?.rerender + 1);
      if (mode === 'highlights') {
        handleExtractHighlights(
          editorTemplate!,
          autoZoomEnabled,
          selectedLength,
          selectedStylePreset.style,
          selectedLayoutType,
          currentFileType,
          magicLookText,
          faceTrackingEnabled,
          selectedLanguage.value,
          userContext?.user?.editor_template?.feature_sliders,
        ); // TODO: pass other new values (e.g. Desktop/Mobile, Style preset)
      } else {
        handleTranscribe(
          editorTemplate!,
          autoZoomEnabled,
          selectedLength,
          selectedStylePreset.style,
          selectedLayoutType,
          currentFileType,
          magicLookText,
          faceTrackingEnabled,
          selectedLanguage.value,
          userContext?.user?.editor_template?.feature_sliders,
        ); // TODO: pass other new values (e.g. Desktop/Mobile, Style preset)
      }
      handleCloseModal();
      setTimeout(() => {
        resetAllChosenValues();
      }, 2000);
    };

    const handleGetMinutes = () => {
      navigate(getPricingRedirectUrl(userContext?.user));
    };

    const handleChooseLength = (value: number) => {
      const userPermissions = userContext?.user?.permissions;

      if (userPermissions && userPermissions.clip_length_request_enabled) {
        setSelectedLength(value);
      } else if (hasSubscription) {
        setSelectedLength(value);
      } else {
        setShowPricingModal(true);
        // navigate('/subscriptions');
      }
    };

    const handleGoProClick = () => {
      if (hasSubscription) {
        navigate('/dashboard');
      } else {
        setShowPricingModal(true);
      }
    };

    const handleCloseMainModal = () => {
      setVideoDetails(null);
      handleCloseModal();
      resetAllChosenValues();
      clearUrlField();
    };

    const handleClosePricingModal = () => {
      setShowPricingModal(false);
    };

    const checkProBlockedStylePreset = (preset: typeof stylePresets[0]) => {
      if (preset.name === 'Word by Word' || preset.name === 'Sentence')
        return false;
      if (
        userContext?.user?.permissions?.animated_captions &&
        userContext?.user?.permissions?.animated_captions[0] === 'ALL'
      ) {
        return false;
      } else {
        return true;
      }
    };

    const handleSelectStylePreset = (
      preset: typeof stylePresets[0],
      allowForAllUsers?: boolean,
    ) => {
      if (allowForAllUsers) {
        setSelectedStylePreset(preset);
        return;
      }
      if (checkProBlockedStylePreset(preset)) {
        setShowPricingModal(true);
      } else {
        setSelectedStylePreset(preset);
      }
    };

    const handleMagicLookInputChange = (text: string) => {
      setMagicLookText(text);
    };

    const handleShowPricingModal = () => {
      setShowPricingModal(true);
    };

    const updateSelectedLanguage = (language: any) => {
      setSelectedLanguage(language);
    };

    const renderedVideoDuration = renderDuration();

    const LayoutStep = () => (
      <div className="block-wrapper" style={{ marginBottom: 20 }}>
        <div className="title-wrapper">
          <div className="section-title">Select your layout</div>
          <div className="section-subtitle">
            Choose on the right the format you would like your video to be
            edited
          </div>
        </div>
        <div className="layout-section">
          <div className="layout-ratios">
            <div>
              <Image width={284} src={LayoutDesktop} preview={false} />
              <div className="layout-subtext">Desktop version 16:9</div>
            </div>
            <div>
              <Image
                wrapperStyle={{ marginBottom: -7 }}
                width={141}
                src={LayoutMobile}
                preview={false}
              />
              <div className="layout-subtext">Mobile version 9:16</div>
            </div>
          </div>
          <div className="mobile-layout-description">
            Your videos will be auto-edited with the format you choose below.
          </div>
          <div className="layout-choose-ratio">
            <div className="layout-options-wrapper">
              <div
                className={`layout-option ${
                  selectedLayoutType === 'desktop' && 'active'
                }`}
                onClick={() => setSelectedLayoutType('desktop')}
              >
                <IconDesktop
                  style={{ height: 153, width: 164 }}
                  className="layout-option-icon"
                />
                <div className="layout-option-button">Desktop</div>
              </div>
              <div
                className={`layout-option ${
                  selectedLayoutType === 'mobile' && 'active'
                }`}
                onClick={() => setSelectedLayoutType('mobile')}
              >
                <IconMobile
                  style={{ height: 153, width: 89 }}
                  className="layout-option-icon"
                />
                <div className="layout-option-button">Mobile</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (showPricingModal)
      return (
        <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay">
          <div className="modal-pricing">
            <Image
              onClick={handleClosePricingModal}
              wrapperStyle={{
                position: 'absolute',
                right: '20px',
                top: '15px',
                cursor: 'pointer',
                zIndex: 4,
              }}
              src={IconCloseCircle}
              preview={false}
              width={32}
            />
            <div className="modal-pricing__left">
              <div className="left-title">
                Try now the best{' '}
                <span style={{ color: COLORS.YELLOW }}>PRO features</span>
              </div>
              <PricingCard
                style={{ maxWidth: '100%' }}
                className="cursor-pointer"
                onClick={() => navigate('/subscriptions')}
              />
              <div
                className="comparisons-and-faq"
                onClick={() => navigate('/subscriptions')}
              >
                Comparisions & FAQ
              </div>
            </div>
            <div className="modal-pricing__right">
              <Image src={PricingModalImage} preview={false} />
              <div className="right-text-container">
                <div className="right-text-container__title">
                  AI-Generated B-Roll
                </div>
                <div className="right-text-container__description">
                  Enhance your videos with AI-powered B-roll for better
                  storytelling and visual appeal, featuring customizable
                  background footage.
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );

    return (
      <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay">
        <div className="modal-main">
          {contextHolder}
          <Image
            onClick={handleCloseMainModal}
            wrapperStyle={{
              position: 'absolute',
              right: '20px',
              top: '15px',
              cursor: 'pointer',
            }}
            src={IconClose}
            preview={false}
            width={16}
          />
          <div className="steps-container">
            {steps.map((step, i) => (
              <div
                key={step.id}
                className={`step ${i <= currentStepIndex && 'active'}`}
              >
                <div className="step-circle" />
                <div className="step-text">{step.name}</div>
                <div className="step-line" />
              </div>
            ))}
          </div>
          {/* Different indexes for Transcribe flow (miss Style step) */}
          {steps[currentStepIndex].id === 'video' && (
            <VideoStep
              mode={mode}
              autoZoomEnabled={autoZoomEnabled}
              setAutoZoomEnabled={setAutoZoomEnabled}
              selectedLanguage={selectedLanguage}
              updateSelectedLanguage={updateSelectedLanguage}
              faceTrackingEnabled={faceTrackingEnabled}
              setFaceTrackingEnabled={setFaceTrackingEnabled}
              loadingVideoDetails={loadingVideoDetails}
              renderImage={renderImage}
              renderVideoName={renderVideoName}
              renderChannel={renderChannel}
              renderViews={renderViews}
              renderedVideoDuration={renderedVideoDuration}
              uploadedVideoDetails={uploadedVideoDetails}
              videoDetails={videoDetails}
              handleMagicLookInputChange={handleMagicLookInputChange}
              handleShowPricingModal={handleShowPricingModal}
              hideGetMinutesButton={false}
              getRemainingMinutes={getRemainingMinutes}
              handleGetMinutes={handleGetMinutes}
              getCreditsAfter={getCreditsAfter}
            />
          )}
          {steps[currentStepIndex].id === 'layout' && <LayoutStep />}
          {steps[currentStepIndex].id === 'style' && (
            <StyleStep
              videoDuration={uploadedVideoDetails?.videoDuration}
              handleSelectStylePreset={handleSelectStylePreset}
              selectedStylePreset={selectedStylePreset}
            />
          )}
          {steps[currentStepIndex].id === 'finalTune' && (
            <FinalStep
              getCreditsAfter={getCreditsAfter}
              handleChooseLength={handleChooseLength}
              selectedLength={selectedLength}
              handleMagicLookInputChange={handleMagicLookInputChange}
              handleShowPricingModal={handleShowPricingModal}
            />
          )}
          {/* {currentStepIndex === 1 && <LayoutStep />}
        {currentStepIndex === 2 && <StyleStep />}
        {currentStepIndex === 3 && <FinalStep />} */}

          <div className="bottom-navigation">
            {currentStepIndex !== 0 ? (
              <div onClick={handlePrevStep} className="back-button">
                Back
              </div>
            ) : (
              <div />
            )}
            {currentStepIndex !== steps.length - 1 ? (
              <div onClick={handleNextStep} className="next-button">
                Next
              </div>
            ) : (
              <div
                style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                {getCreditsAfter() < 0 && (
                  <div
                    className="cursor-pointer yellow-hover go-pro-button"
                    onClick={handleGoProClick}
                  >
                    <span>{hasSubscription ? 'Get minutes' : 'Go Pro'}</span>
                    <Image src={RightArrow} preview={false} />
                  </div>
                )}
                <div
                  onClick={handleGenerateClips}
                  className={`generate-button ${
                    getCreditsAfter() < 0 && 'disabled'
                  }`}
                >
                  Generate Clips
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  },
);

UploadSteps.displayName = 'UploadSteps';

export default UploadSteps;

interface IAvailableCreditsBoxProps {
  hideGetMinutesButton?: boolean;
  getRemainingMinutes: () => any;
  handleGetMinutes: () => any;
  getCreditsAfter: () => any;
}

const AvailableCreditsBox = ({
  hideGetMinutesButton,
  getRemainingMinutes,
  handleGetMinutes,
  getCreditsAfter,
}: IAvailableCreditsBoxProps) => (
  <div className="available-credits-box">
    <div className="available-credits-text">
      Available credits: {getRemainingMinutes()?.toFixed(2)} minutes
    </div>
    <div className="available-credits-text">
      Available credits after:{' '}
      <span className={getCreditsAfter() >= 0 ? 'green' : 'red'}>
        {getCreditsAfter()} minutes
      </span>
    </div>
    {getCreditsAfter() < 0 && !hideGetMinutesButton && (
      <div onClick={handleGetMinutes} className="get-minutes">
        Get minutes
      </div>
    )}
  </div>
);

interface IVideoStepProps {
  loadingVideoDetails: boolean;
  faceTrackingEnabled: boolean;
  autoZoomEnabled: boolean;
  selectedLanguage: { label: string; value: string | null };
  updateSelectedLanguage: (lang: any) => void;
  setFaceTrackingEnabled: (value: boolean) => void;
  setAutoZoomEnabled: (value: boolean) => void;
  renderImage: () => any;
  renderVideoName: () => any;
  renderChannel: () => any;
  renderViews: () => any;
  hideGetMinutesButton: boolean;
  renderedVideoDuration: any;
  uploadedVideoDetails: any;
  videoDetails: any;
  handleMagicLookInputChange: (text: string) => void;
  handleShowPricingModal: () => void;
  getRemainingMinutes: () => any;
  handleGetMinutes: () => any;
  getCreditsAfter: () => any;
  mode: 'highlights' | 'transcribe';
}

const VideoStep = ({
  loadingVideoDetails,
  autoZoomEnabled,
  selectedLanguage,
  faceTrackingEnabled,
  mode,
  setAutoZoomEnabled,
  updateSelectedLanguage,
  setFaceTrackingEnabled,
  renderImage,
  handleShowPricingModal,
  handleMagicLookInputChange,
  renderVideoName,
  renderChannel,
  handleGetMinutes,
  renderViews,
  getRemainingMinutes,
  getCreditsAfter,
  hideGetMinutesButton,
  renderedVideoDuration,
  videoDetails,
  uploadedVideoDetails,
}: IVideoStepProps) => {
  const [showMagicLookInput, setShowMagicLookInput] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const userContext = useContext(UserContext);
  const userPermissions = userContext?.user?.permissions;
  const isFaceTrackingDisabledByPermissions = Boolean(
    !userContext?.user?.permissions ||
      !userContext?.user.permissions?.hd_enabled,
  );
  const { width } = useWindowDimensions();
  const isMobile = width < 920;
  const navigate = useNavigate();

  const handleMagicLookButtonClick = () => {
    if (userPermissions && userPermissions.clip_length_request_enabled) {
      setShowMagicLookInput(true);
    } else {
      handleShowPricingModal();
    }
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  const handleClickFaceTrackingSwitch = () => {
    if (isFaceTrackingDisabledByPermissions) {
      setShowPricingModal(true);
    } else {
      setFaceTrackingEnabled(!faceTrackingEnabled);
    }
  };

  const handleClickAutoZoomSwitch = () => {
    if (isFaceTrackingDisabledByPermissions) {
      setShowPricingModal(true);
    } else {
      setAutoZoomEnabled(!autoZoomEnabled);
    }
  };

  const handleClosePricingModal = () => {
    setShowPricingModal(false);
  };

  return (
    <div>
      <PricingModal
        isOpen={showPricingModal}
        handleClosePricingModal={handleClosePricingModal}
      />

      <div className="title-wrapper">
        <div className="section-title">AI Curation</div>
        <div className="section-subtitle">
          This video will be processed by our AI Clip Generator
        </div>
      </div>
      <div className="video-section">
        {loadingVideoDetails ? (
          <div
            style={{ marginBottom: 70, marginTop: 20 }}
            className="video-preview-wrapper"
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 60, color: COLORS.PRIMARY }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <div className="video-preview-wrapper">
            <div>
              <Image
                style={{ borderRadius: '8px 8px 0 0', maxHeight: 300 }}
                height="auto"
                width="100%"
                src={renderImage() || UploadFromComputerImage}
                preview={false}
              />
            </div>
            <div className="video-preview-content">
              <div className="video-preview-content__top">
                {/* <div className="video-name-pill">Video Name</div> */}
                <div className="video-name-text">
                  {renderVideoName() || uploadedVideoDetails?.videoTitle}
                </div>
              </div>
              <div className="video-preview-content__bottom">
                {renderChannel() && (
                  <div className="video-preview-content__pill">
                    <span className="pill__title">Channel:</span>
                    <span className="pill__text">{renderChannel()}</span>
                  </div>
                )}
                <div className="video-preview-content__pill">
                  <span className="pill__title">Duration:</span>
                  <span className="pill__text">
                    {typeof renderedVideoDuration === 'number' &&
                    renderedVideoDuration
                      ? renderedVideoDuration?.toFixed(2)
                      : renderedVideoDuration}
                    min
                  </span>
                </div>
                {videoDetails?.statistics?.viewCount && (
                  <div className="video-preview-content__pill">
                    <span className="pill__title">Views:</span>
                    <span className="pill__text">{renderViews()}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="video-step__right">
          <AvailableCreditsBox
            hideGetMinutesButton={hideGetMinutesButton}
            getRemainingMinutes={getRemainingMinutes}
            handleGetMinutes={handleGetMinutes}
            getCreditsAfter={getCreditsAfter}
          />
          <div className="face-tracking__toggle-container">
            <div className="face-tracking__label">
              <span>Face Tracking</span>
              <Tooltip
                overlayClassName="tooltip face-tracking__tooltip"
                title="This toggle allows you to control whether to activate Face Tracking for your video. Not every video will benefit from it. Have fun!"
              >
                <IconInfoCircle className="face-tracking__info" />
              </Tooltip>
              {isFaceTrackingDisabledByPermissions && (
                <div className="face-pro-tag">PRO</div>
              )}
            </div>

            <div className="face-switch">
              <div className="check">
                <input
                  checked={faceTrackingEnabled}
                  onChange={handleClickFaceTrackingSwitch}
                  id="face-switch-check"
                  type="checkbox"
                />
                <label htmlFor="face-switch-check"></label>
              </div>
            </div>
          </div>

          {faceTrackingEnabled && (
            <div className="face-tracking__toggle-container">
              <div className="face-tracking__label">
                <span className="beta">Auto-Zoom</span>
                <Tooltip
                  overlayClassName="tooltip face-tracking__tooltip"
                  title="This experimental feature automatically zooms in on your face while you're speaking, aiming to enhance viewer engagement with your video."
                >
                  <IconInfoCircle className="face-tracking__info" />
                </Tooltip>
              </div>

              <div className="face-switch no-face">
                <div className="check">
                  <input
                    checked={autoZoomEnabled}
                    onChange={handleClickAutoZoomSwitch}
                    id="auto-zoom-switch-check"
                    type="checkbox"
                  />
                  <label htmlFor="auto-zoom-switch-check"></label>
                </div>
              </div>
            </div>
          )}
          <div className="language-dropdown__container">
            <Select
              className="language-select"
              classNamePrefix="language-select-prefix"
              value={selectedLanguage}
              onChange={(result) => updateSelectedLanguage(result)}
              options={supportedLanguages}
              components={{ DropdownIndicator }}
            />
          </div>
          {!isMobile && mode === 'highlights' && (
            <>
              {showMagicLookInput ? (
                <div className="magic-look-input-wrapper">
                  <div className="ai-generator-input-title">
                    <IconMagicWand />
                    <span>Magic Look</span>
                  </div>
                  <AIGeneratorTextArea
                    handleAiInputChange={handleMagicLookInputChange}
                    placeholder="Insert a prompt to look for something specific in the video"
                  />
                </div>
              ) : (
                <div
                  className="magic-look-btn"
                  onClick={handleMagicLookButtonClick}
                >
                  <span>I&apos;m looking for a specific topic</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

interface IFinalStepProps {
  getCreditsAfter: () => any;
  handleChooseLength: (value: any) => void;
  selectedLength: any;
  handleMagicLookInputChange: (text: string) => void;
  handleShowPricingModal: () => void;
}

const FinalStep = ({
  getCreditsAfter,
  handleChooseLength,
  selectedLength,
  handleMagicLookInputChange,
  handleShowPricingModal,
}: IFinalStepProps) => {
  return (
    <div className="block-wrapper" style={{ marginBottom: 30 }}>
      <div
        className={`title-wrapper ${
          getCreditsAfter() < 0 && 'final-available-credits-wrapper'
        }`}
      >
        <div className="section-title">Last step</div>
        {/* {getCreditsAfter() < 0 &&
          <AvailableCreditsBox hideGetMinutesButton />
        } */}
      </div>
      <div className="final-section">
        <div className="final-description">
          <div className="pro-feature-text">
            Pro Feature <IconStar />
          </div>
          <div className="preferred-length-text">
            Choose your preferred clip length
          </div>
        </div>
        <div className="length-options">
          {lengthOptions.map((option) => (
            <div
              key={option.value}
              onClick={() => handleChooseLength(option.value)}
              className={`length-pill ${
                option.value === selectedLength && 'active'
              }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
