import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '../../data/userContext';
import { ReactComponent as IconStarNoColor } from '../../assets/icon-star-no-color.svg';
import Select, { DropdownIndicatorProps, components } from 'react-select';

import StyleOneGif from '../../assets/style-one.gif';
import StyleTwoGif from '../../assets/style-two.gif';
import StyleThreeGif from '../../assets/style-three.gif';
import StyleFourGif from '../../assets/style-four.gif';
import { useWindowDimensions } from '../../pages/Editor/Editor.page';
import { ReactComponent as DropdownIndicatorIcon } from '../../assets/dropdown-indicator-small.svg';
import { ReactComponent as ColorWheelIcon } from '../../assets/color-wheel.svg';
import { ReactComponent as BlockIcon } from '../../assets/block-icon.svg';
import { ReactComponent as MagicWandIcon } from '../../assets/magic-wand.svg';
import { ReactComponent as BrushIcon } from '../../assets/brush.svg';
import { ReactComponent as BackIcon } from '../../assets/back.svg';
import { SketchPicker } from 'react-color';
import { fontSizeOptions } from '../../pages/Editor/Templates/Templates';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { IEditorTemplate, IStreamer } from '../../data/intefaces/streamer.interface';
import { completeOnboardingStep, convertToBoolean, getEnabledAnimatedCaptionsForUser } from '../../utils';
import { PricingModal } from '../pricing-modal/PricingModal';
import { Switch } from 'antd';
import axios from 'axios';
import { OnboardingContext } from '../../data/onboardingContext';
import { WatermarkBlock } from '../../pages/Editor/BrandingTab/WatermarkBlock';
import { v4 as uuid } from 'uuid';
import { WatermarkSizeHeight } from '../../constants/content-constants';

const stylePresets = [
  {
    id: 2,
    label: 'Captions Style',
    name: 'Sentence',
    availableToAll: true,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'none'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 1,
    label: 'Captions Style',
    name: 'Word by Word',
    availableToAll: true,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'none'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 4,
    label: 'Captions Style',
    name: 'Bounce',
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'bounce'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 3,
    label: 'Captions Style',
    name: 'Background Pop',
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'wword'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
];


const additionalStyles = [
  {
    id: 4,
    label: 'Bounce',
    name: 'Bounce',
    animationName: 'bounce-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'bounce'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 5,
    label: 'Karaoke',
    name: 'Karaoke',
    animationName: 'karaoke-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'karaoke'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 3,
    label: 'Background Pop',
    name: 'Background Pop',
    animationName: 'wword-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'true',
      captions_animation_type: 'wword'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 6,
    label: 'Blossom',
    name: 'Blossom',
    animationName: 'blossom',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'blossom'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },

  {
    id: 7,
    label: 'Sunshine',
    name: 'Sunshine',
    animationName: 'sunshine',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'sunshine'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 8,
    label: 'Emerald',
    name: 'Emerald',
    animationName: 'emerald',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'emerald'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
  {
    id: 8,
    label: 'Slide-Left',
    name: 'Slide-Left',
    animationName: 'slide-left-infinite',
    availableToAll: false,
    style: {
      background: '#000000',
      color: '#FFFFFF',
      textShadow: 'Heavy',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      fontSize: 'Big',
      isHighlighted: false,
      alignItems: 'Bottom',
      fontFamily: 'Impact',
      wordLevel: 'false',
      captions_animation_type: 'slide-left'
    },
    styleForFrontend: {
      color: '#FFFFFF',
    }
  },
];


const boxData = [
  {
    animationName: 'wword',
    isActive: true,
  },
  {
    animationName: 'karaoke',
    isActive: true,
  },
  {
    animationName: 'bounce',
    isActive: true,
  },
  {
    animationName: 'blossom',
    isActive: true,
  },
  {
    animationName: 'sunshine',
    isActive: true,
  },
  {
    animationName: 'emerald',
    isActive: true,
  },
  {
    animationName: 'slide-left',
    isActive: true,
  },
  // {
  //   animationName: 'bubble',
  //   isActive: false,
  // },
  // {
  //   animationName: 'color-wave',
  //   isActive: false,
  // },

  // {
  //   animationName: 'underline',
  //   isActive: false,
  // },
  // {
  //   animationName: 'shake',
  //   isActive: false,
  // },
  // {
  //   animationName: 'rotate',
  //   isActive: false,
  // },
];

const fontFamilyOptions = {
  name: 'fontFamily',
  options: [
    { value: 'Impact', label: 'Impact', icon: 'AliwangwangOutlined' },
    { value: 'Arial', label: 'Arial', icon: 'AliwangwangOutlined' },
    { value: 'Georgia', label: 'Georgia', icon: 'AliwangwangOutlined' },
    {
      value: 'Times New Roman',
      label: 'Times New Roman',
      icon: 'AliwangwangOutlined',
    },
    // { value: 'Verdana', label: 'Verdana', icon: 'AliwangwangOutlined' },
    { value: 'Roboto', label: 'Roboto', icon: 'AliwangwangOutlined' },
    { value: 'Poppins', label: 'Poppins', icon: 'AliwangwangOutlined' },
    {
      value: 'Montserrat',
      label: 'Montserrat',
      icon: 'AliwangwangOutlined',
    },
    {
      value: 'Roboto Condensed',
      label: 'Roboto Condensed',
      icon: 'AliwangwangOutlined',
    },
    { value: 'Open Sans', label: 'Open Sans', icon: 'AliwangwangOutlined' },
    {
      value: 'Bebas Neue',
      label: 'Bebas Neue',
      icon: 'AliwangwangOutlined',
    },
    {
      value: 'Belanosima',
      label: 'Belanosima',
      icon: 'AliwangwangOutlined',
    },
    { value: 'Caprasimo', label: 'Caprasimo', icon: 'AliwangwangOutlined' },
    {
      value: 'Red Hat Display',
      label: 'Red Hat Display',
      icon: 'AliwangwangOutlined',
    },
    { value: 'Work Sans', label: 'Work Sans', icon: 'AliwangwangOutlined' },
    { value: 'Kanit', label: 'Kanit', icon: 'AliwangwangOutlined' },
    { value: 'Oswald', label: 'Oswald', icon: 'AliwangwangOutlined' },
    { value: 'Koulen', label: 'Koulen', icon: 'AliwangwangOutlined' },
  ],
};

const gifUrls = [
  StyleTwoGif,
  StyleOneGif,
  StyleFourGif,
  StyleThreeGif,
];

const DEFAULT_TEMPLATE = {
  "blurred_background": true,
  "webcam_enabled": false,
  "feed_height": 309.36170212765956,
  "feed_output_height": 173.69370567375887,
  "feed_output_width": 173.69370567375887,
  "feed_output_x": 0,
  "feed_output_y": 49.94902482269504,
  "feed_type": "square",
  "feed_width": 309.36170212765956,
  "feed_x": 118.5886524822695,
  "feed_y": 0,
  "webcam_height": 73.47340425531914,
  "webcam_output_height": 97.80341312056737,
  "webcam_output_width": 173.69370567375887,
  "webcam_output_x": 0,
  "webcam_output_y": 0,
  "webcam_type": "landscape",
  "webcam_width": 131.90839243498817,
  "webcam_x": 0,
  "webcam_y": 0,
  "subtitles_settings": {
    "background": "#000000",
    "color": "#FFFFFF",
    "textShadow": "Medium",
    "fontWeight": "normal",
    "textTransform": "uppercase",
    "fontSize": "Huge",
    "isHighlighted": false,
    "alignItems": "Middle",
    "fontFamily": "Arial",
    "wordLevel": "false",
    "captions_animation_type": "none"
  }
}

const MOBILE_WIDTH = 1080;
const MOBILE_HEIGHT = 1920;

const DESKTOP_WIDTH = 1920;
const DESKTOP_HEIGHT = 1080;

const getHardcodedCoordinatesFromPositionMobile = (position: string, height: number, width: number): { x: number, y: number } => {
  switch (position) {
    case 'top-left':
      return {
        x: 100,
        y: 100
      }
    case 'top-right':
      return {
        x: MOBILE_WIDTH - 100 - width,
        y: 100
      }
    case 'bottom-left':
      return {
        x: 100,
        y: MOBILE_HEIGHT - 100 - height
      }
    case 'bottom-right':
      return {
        x: MOBILE_WIDTH - 100 - width,
        y: MOBILE_HEIGHT - 100 - height
      }
    default:
      return {
        x: 100,
        y: 100
      };
  }
}
const getHardcodedCoordinatesFromPositionDesktop = (position: string, height: number, width: number): { x: number, y: number } => {
  switch (position) {
    case 'top-left':
      return {
        x: 100,
        y: 100
      }
    case 'top-right':
      return {
        x: DESKTOP_WIDTH - 100 - width,
        y: 100
      }
    case 'bottom-left':
      return {
        x: 100,
        y: DESKTOP_HEIGHT - 100 - height
      }
    case 'bottom-right':
      return {
        x: DESKTOP_WIDTH - 100 - width,
        y: DESKTOP_HEIGHT - 100 - height
      }
    default:
      return {
        x: 100,
        y: 100
      };
  }
}


const getPreviewWatermarkPositionStyles = (position: string) => {
  switch (position) {
    case 'top-left':
      return {
        top: '20px',
        left: '20px'
      }
    case 'top-right':
      return {
        top: '20px',
        right: '20px'
      }
    case 'bottom-left':
      return {
        bottom: '20px',
        left: '20px'
      }
    case 'bottom-right':
      return {
        bottom: '20px',
        right: '20px'
      }
    case 'custom':
      return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }
    default:
      return {
        top: '20px',
        left: '20px'
      }
  }
}


interface IStyleStepProps {
  handleSelectStylePreset: (preset: any, allowForAllUsers?: boolean) => void;
  selectedStylePreset: any;
  videoDuration?: number;
}

export const StyleStep = ({ handleSelectStylePreset, selectedStylePreset, videoDuration }: IStyleStepProps) => {
  const userContext = useContext(UserContext);
  const editorTemplate = userContext?.user?.editor_template;
  const [selectedStyleInDropdown, setSelectedStyleInDropdown] = useState(additionalStyles[4]);
  const [forceRender, setForceRender] = useState(false);
  const [isPricingModalOpened, setIsPricingModalOpened] = useState(false);

  const [chosenColor, setChosenColor] = useState('');
  const [chosenStrokeColor, setChosenStrokeColor] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openStrokeColorPicker, setOpenStrokeColorPicker] = useState(false);
  const [showTemplateStep, setShowTemplateStep] = useState(false);
  const [showBrandingScreen, setShowBrandingScreen] = useState(false);
  const [loading, setLoading] = useState(false);

  const templateFeatureSliders = userContext?.user?.editor_template?.feature_sliders;

  const colorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenColorPicker(false) });
  const strokeColorPickerRef = useDetectClickOutside({ onTriggered: () => setOpenStrokeColorPicker(false) });
  const onboardingContext = useContext(OnboardingContext);

  const isBoldFont = editorTemplate?.subtitles_settings?.fontWeight === 'bold';
  const isUppercaseFont = editorTemplate?.subtitles_settings?.textTransform === 'uppercase';
  const isSubtitlesEnabled = typeof editorTemplate?.subtitles_settings?.subtitles_enabled === 'boolean' ?
    editorTemplate?.subtitles_settings?.subtitles_enabled : true;
  const isWithBlurredBackground = typeof editorTemplate?.subtitles_settings?.blurred_background === 'boolean' ?
    editorTemplate?.subtitles_settings?.blurred_background : true;
  const isGroupLevel = !convertToBoolean(editorTemplate?.subtitles_settings?.wordLevel);
  const animationType = editorTemplate?.subtitles_settings?.captions_animation_type;

  useEffect(() => {
    const isTemplateAvailable = Boolean(editorTemplate && editorTemplate?.subtitles_settings?.alignItems);
    setShowTemplateStep(isTemplateAvailable);
    if (isTemplateAvailable) {
      handleSelectStylePreset({
        id: 10,
        label: 'Template Styles',
        name: 'template',
        animationName: '',
        availableToAll: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style: editorTemplate?.subtitles_settings,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        styleForFrontend: {}
      }, true);
    }

    completeOnboardingStep('choose_template', userContext?.user);
    onboardingContext?.setRerender(onboardingContext?.rerender + 1);
  }, [])



  const getAnimatedCaptionsForUser = (user: IStreamer | null | undefined): string[] => {
    if (user === null || user === undefined) {
      return [];
    }

    if (!animatedCaptionsTabEnabledForUser) return [];

    if (user?.permissions) {
      return user?.permissions.animated_captions;
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return ['wword', 'karaoke', 'bounce'];
    }

    return [];
  };

  const getAllowedAnimatedCaptions = (user: IStreamer | null | undefined): boolean => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return user?.permissions.animated_captions[0] === 'ALL';
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return true;
    }

    return false;
  };


  const animatedCaptionsTabEnabledForUser = getEnabledAnimatedCaptionsForUser(userContext?.user);

  const allowedCaptionsList = getAnimatedCaptionsForUser(userContext?.user); // userContext?.user?.permissions.animated_captions;
  const allowAllAnimations = getAllowedAnimatedCaptions(userContext?.user);  //userContext?.user?.permissions.animated_captions[0] === 'ALL';

  const handleOpenColorPicker = () => {
    setOpenColorPicker(open => !open);
    setOpenStrokeColorPicker(false);
  };

  const handleOpenStrokeColorPicker = () => {
    setOpenStrokeColorPicker(open => !open);
    setOpenColorPicker(false);
  };

  const handleTemplateFieldChange = (fieldName: string, value: string | boolean | any[], addToRoot?: boolean) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const templateCopy: IEditorTemplate = { ...editorTemplate };
    const subtitlesSettingsCopy = templateCopy?.subtitles_settings;
    if (subtitlesSettingsCopy) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subtitlesSettingsCopy[fieldName] = value;
      templateCopy.subtitles_settings = subtitlesSettingsCopy;

      if (addToRoot) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        templateCopy[fieldName] = value;
      }
      const user = userContext!.user;
      user!.editor_template = templateCopy;

      userContext?.setUser(user!);
      setForceRender(prevState => !prevState);
    }

  }

  const handleSubtitlesEnable = (value: boolean) => {
    handleTemplateFieldChange('subtitles_enabled', value);
  };

  const handleBlurredBackgroundChange = (value: boolean) => {
    handleTemplateFieldChange('blurred_background', value);
  };

  const handleFontWeightChange = () => {
    handleTemplateFieldChange('fontWeight', isBoldFont ? 'normal' : 'bold');
  };

  const handleTransformChange = () => {
    handleTemplateFieldChange('textTransform', isUppercaseFont ? 'none' : 'uppercase')
  };

  const handleStrokeChange = (value: string) => {
    handleTemplateFieldChange('textShadow', value);
  };

  const handleChooseColor = (color: any) => {
    setChosenColor(color);
    handleTemplateFieldChange('color', color.hex);
  };

  const handleChooseStrokeColor = (color: any) => {
    setChosenStrokeColor(color);
    handleTemplateFieldChange('background', color.hex);
  };

  const handleChooseAlignment = (position: string) => {
    handleTemplateFieldChange('alignItems', position);
  };

  const handleFontSizeChange = (fontSize: string) => {
    handleTemplateFieldChange('fontSize', fontSize)
  };

  const handleChooseCaptionGrouping = (isGroupLevel: boolean) => {
    handleTemplateFieldChange('wordLevel', isGroupLevel ? 'false' : 'true');
  };

  const handleClick = (animationName: string) => {
    if (animationName === 'none') {
      handleTemplateFieldChange('captions_animation_type', 'none');
      return;
    }
    if (!allowAllAnimations && allowedCaptionsList?.indexOf(animationName) === -1 && (boxData.find((item) => item.animationName === animationName)?.isActive)) {
      handleOpenPricingModal();
      return;
    }
    if (boxData.find((item) => item.animationName === animationName)?.isActive) {
      handleTemplateFieldChange('captions_animation_type', animationName);
    }
  };

  const onAnimationHoverEnter = (animation: string) => {
    // Add the "slow" class when hovering over the element
    const element = document.getElementById(animation);
    if (element) {
      element.classList.add(animation);
    }
  };
  const onAnimationHoverLeave = (animation: string) => {
    // Remove the "slow" class when leaving the element
    const element = document.getElementById(animation);
    if (element) {
      element.classList.remove(animation);
    }
  };

  const handleShowBrandingScreen = () => {
    setShowBrandingScreen(true);
  }

  const handleBackToStyling = () => {
    setShowBrandingScreen(false);
  }

  const handleResetToDefault = () => {
    setShowTemplateStep(false);
    handleSelectStylePreset(stylePresets[0]);
  }

  const saveUpdatedTemplate = (template: any) => {
    const data = {
      editor_template: template
    }

    axios.put('/streamer/update-user-editor-template', data).catch((error: { response: any; }) => {
      if (error.response) {
        console.log(error.response);
      }
    });
  }

  const handleShowDetailedTemplate = () => {
    if (!editorTemplate?.subtitles_settings) {
      const user = userContext!.user;

      const copyDefaultTemplate = { ...DEFAULT_TEMPLATE };
      copyDefaultTemplate.subtitles_settings = selectedStylePreset.style;

      user!.editor_template = copyDefaultTemplate;
      userContext?.setUser(user!);
      saveUpdatedTemplate(copyDefaultTemplate);
    }
    setTimeout(() => {
      setShowTemplateStep(true);
    }, 100);
  }

  const { width } = useWindowDimensions();
  const isMobile = width < 920;

  const presets = {
    emoji_horizontal_orientation: {
      left: 'start',
      center: 'center',
      right: 'end'
    },
    textShadow: {
      Light: '0.0432em 0.0432em 0 var(--text-shadow-color), -0.0216em -0.0216em 0 var(--text-shadow-color),-0.0216em 0.0216em 0 var(--text-shadow-color), 0.0216em -0.0216em 0 var(--text-shadow-color), 0.0216em 0.0216em 0 var(--text-shadow-color)',
      Medium: '0.0px 2.34375px 0.0046875px var(--text-shadow-color), 2.296875px 0.4921875px 0.0046875px var(--text-shadow-color), 0.984375px -2.1328125px 0.0046875px var(--text-shadow-color), -1.875px -1.40625px 0.0046875px var(--text-shadow-color), -1.78125px 1.5234375px 0.0046875px var(--text-shadow-color), 1.125px 2.0625px 0.0046875px var(--text-shadow-color), 2.25px -0.65625px 0.0046875px var(--text-shadow-color), -0.1640625px -2.34375px 0.0046875px var(--text-shadow-color), -2.3203125px -0.3515625px 0.0046875px var(--text-shadow-color)',
      Heavy: '0.0px 5.0px 0.01px var(--text-shadow-color), 4.9px 1.05px 0.01px var(--text-shadow-color), 2.1px -4.55px 0.01px var(--text-shadow-color), -4.0px -3.0px 0.01px var(--text-shadow-color), -3.8px 3.25px 0.01px var(--text-shadow-color), 2.4px 4.4px 0.01px var(--text-shadow-color), 4.8px -1.4px 0.01px var(--text-shadow-color), -0.35px -5.0px 0.01px var(--text-shadow-color), -4.95px -0.75px 0.01px var(--text-shadow-color), -1.75px 4.7px 0.01px var(--text-shadow-color), 4.2px 2.7px 0.01px var(--text-shadow-color), 3.55px -3.5px 0.01px var(--text-shadow-color), -2.7px -4.2px 0.01px var(--text-shadow-color), -4.7px 1.75px 0.01px var(--text-shadow-color), 0.7px 4.95px 0.01px var(--text-shadow-color), 5.0px 0.4px 0.01px var(--text-shadow-color), 1.45px -4.8px 0.01px var(--text-shadow-color), -4.35px -2.4px 0.01px var(--text-shadow-color), -3.3px 3.75px 0.01px var(--text-shadow-color), 2.95px 4.0px 0.01px var(--text-shadow-color), 4.55px -2.05px 0.01px var(--text-shadow-color), -1.05px -4.9px 0.01px var(--text-shadow-color), -5.0px -0.05px 0.01px var(--text-shadow-color), -1.1px 4.9px 0.01px var(--text-shadow-color), 4.55px 2.1px 0.01px var(--text-shadow-color), 3.05px -4.0px 0.01px var(--text-shadow-color), -3.25px -3.8px 0.01px var(--text-shadow-color), -4.4px 2.35px 0.01px var(--text-shadow-color), 1.35px 4.8px 0.01px var(--text-shadow-color), 4.8px -1.0px 0.01px var(--text-shadow-color), 0.1px -5.0px 0.01px var(--text-shadow-color), -4.85px -1.15px 0.01px var(--text-shadow-color), -2.15px 4.5px 0.01px var(--text-shadow-color), 3.95px 3.05px 0.01px var(--text-shadow-color)',
    },
    fontWeight: {
      normal: '400',
      bold: '700',
    } as const,
  };

  const checkProBlockedStylePreset = (preset: typeof stylePresets[0]) => {
    if (preset.name === 'Word by Word' || preset.name === 'Sentence') return false;
    if (userContext?.user?.permissions?.animated_captions && userContext?.user?.permissions?.animated_captions[0] === 'ALL') {
      return false;
    } else {
      return true;
    }
  };

  const getAlignItems = (align: string | undefined) => {
    if (align === 'Top') return 'start';
    else if (align === 'Middle') return 'center';
    else if (align === 'Bottom') return 'end';
    else return 'center'
  }

  const getFontSize = (fontSize: string | undefined) => {
    if (fontSize === 'Huge') return '28px';
    else if (fontSize === 'Big') return '24px';
    else if (fontSize === 'Medium') return '20px';
    else if (fontSize === 'Small') return '16px';
    else return '20px'
  }

  const getStylesForTemplateCardWrapper = () => {
    if (!showTemplateStep) return {};
    return {
      justifyContent: getAlignItems(editorTemplate?.subtitles_settings?.alignItems)
    }
  }

  const getStylesForTemplateCardText = () => {
    if (!showTemplateStep) return {};
    return {
      '--text-shadow-color': editorTemplate?.subtitles_settings?.background,
      color: editorTemplate?.subtitles_settings?.color,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      textShadow: presets.textShadow[editorTemplate?.subtitles_settings?.textShadow || 'Medium'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fontWeight: presets.fontWeight[editorTemplate?.subtitles_settings?.fontWeight || 'normal'],
      textTransform: editorTemplate?.subtitles_settings?.textTransform,
      fontSize: getFontSize(editorTemplate?.subtitles_settings?.fontSize),
      fontFamily: editorTemplate?.subtitles_settings?.fontFamily,
      zIndex: 1
    }
  }

  const handleOpenPricingModal = () => {
    setIsPricingModalOpened(true);
  };

  const handleClosePricingModal = () => {
    setIsPricingModalOpened(false);
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </components.DropdownIndicator>
    );
  };

  const Option = (props: any) => {
    return (
      <div style={{ fontFamily: props.value }}>
        <components.Option {...props} />
      </div>
    );
  };

  const styles = useMemo(() => {
    return {
      boxContainer: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center',
        padding: '20px',
        height: '100%',
        background: '#272836',
        overflow: 'auto'
      },
      box: {
        background: '#2C2C2F',
        color: 'var(--White, #FFF)',
        textAlign: 'center' as const,
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        maxHeight: '98px',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        border: '3px solid transparent',
        position: 'relative' as const,
      },
      focusedBox: {
        border: '3px solid rgba(255, 185, 248, 0.50)',
      },
      smallText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'var(--White, #67FFCC)',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
      inactiveBox: {
        opacity: 0.5, // Make inactive boxes grayed out
        cursor: 'not-allowed', // Make inactive boxes not clickable
      },
      comingSoonText: {
        position: 'absolute' as const,
        bottom: '5px',
        left: '0',
        right: '0',
        color: 'orange',
        fontFamily: 'Poppins',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '14.218px',
      },
    };
  }, []);

  const watermarkFromTemplate = templateFeatureSliders && templateFeatureSliders.length > 0 && templateFeatureSliders.find(slider => slider.type === 'watermark');
  const watermarkFeatureSlider = { ...watermarkFromTemplate };
  if (watermarkFeatureSlider && watermarkFeatureSlider?.position === 'custom') {
    watermarkFeatureSlider.position = 'top-left';
    watermarkFeatureSlider.desktopDymension = {
      x: 100,
      y: 100,
      height: watermarkFeatureSlider.desktopDymension.height,
      width: watermarkFeatureSlider.desktopDymension.width,
      ratio: watermarkFeatureSlider.desktopDymension.ratio
    }
    watermarkFeatureSlider.mobileDymension = {
      x: 100,
      y: 100,
      height: watermarkFeatureSlider.mobileDymension.height,
      width: watermarkFeatureSlider.mobileDymension.width,
      ratio: watermarkFeatureSlider.mobileDymension.ratio
    }
  }

  useEffect(() => {
    if (editorTemplate?.feature_sliders && editorTemplate?.feature_sliders.find((slider: any) => slider.position === 'custom')) {
      handleTemplateFieldChange('feature_sliders', [watermarkFeatureSlider], true);
    }
  }, []);

  const handleChooseWatermark = async (imageUrl: string, ratio: number, position: string, size?: string) => {
    const sliders = addWatermarkToFeatureSliders(imageUrl, ratio, position, size);
    const template = { ...userContext?.user?.editor_template } as IEditorTemplate;
    template.feature_sliders = [...sliders];

    const data = {
      editor_template: template
    };

    setLoading(true);
    const response = await axios.put('/streamer/update-user-editor-template', data).catch((error: { response: any; }) => {
      if (error.response) {
        console.log(error.response);
      }
    });

    if (response?.data) {
      userContext?.setUser(response?.data);
    } else {
      const user = userContext!.user;
      user!.editor_template = template;
      userContext?.setUser(user!);
    }


    setLoading(false);
  }

  const addWatermarkToFeatureSliders = (imageUrl: string, ratio: number, position: string, size?: string) => {
    const DEFAULT_HEIGHT = getHeightBySize(size || 'medium');
    const DEFAULT_WIDTH = DEFAULT_HEIGHT * ratio;
    const copySliders = templateFeatureSliders ? [...templateFeatureSliders] : [];
    const isCustomPosition = position === 'custom';

    if (copySliders.find((slider: any) => slider.type === 'watermark')) {
      const updatedCopy = copySliders.map(slider => {
        if (slider.type === 'watermark') {
          return {
            ...slider,
            imageUrl: imageUrl,
            position: position,
            startEndTime: [0, 999999],

            mobileDymension: {
              x: isCustomPosition ? slider.mobileDymension.x : getHardcodedCoordinatesFromPositionMobile(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).x,
              y: isCustomPosition ? slider.mobileDymension.y : getHardcodedCoordinatesFromPositionMobile(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).y,
              height: DEFAULT_HEIGHT,
              width: DEFAULT_WIDTH,
              ratio: ratio
            },
            desktopDymension: {
              x: isCustomPosition ? slider.desktopDymension.x : getHardcodedCoordinatesFromPositionDesktop(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).x,
              y: isCustomPosition ? slider.desktopDymension.y : getHardcodedCoordinatesFromPositionDesktop(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).y,
              height: DEFAULT_HEIGHT,
              width: DEFAULT_WIDTH,
              ratio: ratio
            }
          };
        }
        return slider;
      });

      return updatedCopy;
    } else {
      copySliders.push({
        id: uuid(),
        featureName: 'Watermark',
        color: 'rgba(255, 177, 177, 0.70)',
        startEndTime: [0, 999999],
        // startEndTime: [0, videoDuration],
        type: 'watermark',
        imageUrl: imageUrl,
        position: position,
        mobileDymension: {
          x: getHardcodedCoordinatesFromPositionMobile(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).x,
          y: getHardcodedCoordinatesFromPositionMobile(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).y,
          height: DEFAULT_HEIGHT,
          width: DEFAULT_WIDTH,
          ratio: ratio
        },
        desktopDymension: {
          x: getHardcodedCoordinatesFromPositionDesktop(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).x,
          y: getHardcodedCoordinatesFromPositionDesktop(position, DEFAULT_HEIGHT, DEFAULT_WIDTH).y,
          height: DEFAULT_HEIGHT,
          width: DEFAULT_WIDTH,
          ratio: ratio
        }
      })

      return copySliders;
    }
  }

  const getHeightBySize = (size: string) => {
    switch (size) {
      case 'big':
        return WatermarkSizeHeight.big;
      case 'medium':
        return WatermarkSizeHeight.medium;
      case 'small':
        return WatermarkSizeHeight.small;
      default:
        return WatermarkSizeHeight.medium;
    }
  }

  const handleSelectNoneWatermark = async () => {
    const copySliders = templateFeatureSliders ? [...templateFeatureSliders] : [];

    if (copySliders.find((slider: any) => slider.type === 'watermark')) {
      const updatedCopy = copySliders.filter(slider => slider.type !== 'watermark');

      const template = { ...userContext?.user?.editor_template } as IEditorTemplate;
      template.feature_sliders = [...updatedCopy];

      setLoading(true);
      const data = {
        editor_template: template
      };

      const response = await axios.put('/streamer/update-user-editor-template', data).catch((error: { response: any; }) => {
        if (error.response) {
          console.log(error.response);
        }
      });

      if (response?.data) {
        userContext?.setUser(response?.data);
      } else {
        const user = userContext!.user;
        user!.editor_template = template;
        userContext?.setUser(user!);
      }

      setLoading(false);
    }
  }

  const getStylePreviewWatermark = (): React.CSSProperties => {
    let width = watermarkFeatureSlider?.mobileDymension?.width;
    let height = watermarkFeatureSlider?.mobileDymension?.height;

    if (width > 400) {
      width = width / 7;
      height = height / 7;
    } else {
      width = width / 4;
      height = height / 4;
    }

    return {
      position: 'absolute',
      height: height,
      width: width,
      ...getPreviewWatermarkPositionStyles(watermarkFeatureSlider?.position)
    }
  }


  const getImageGenerationEnabledForUser = (user: IStreamer | null | undefined): boolean | undefined => {
    if (user === null || user === undefined) {
      return false;
    }

    if (user?.permissions) {
      return userContext?.user?.permissions.image_generation_enabled;
    }

    if (user?.subscriptions && user?.subscriptions[0]) {
      return true;
    }

    return false;

  };



  if (showTemplateStep) {
    return (
      <div className="template-step" style={{ marginBottom: 30 }}>
        <PricingModal isOpen={isPricingModalOpened} handleClosePricingModal={handleClosePricingModal} />
        {!isMobile &&
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px 30px' }}>
              <div className='template-step__subtitles-switch'>
                <span className='label'>Subtitles</span>
                <Switch
                  className="ios-switch"
                  checked={isSubtitlesEnabled}
                  onChange={(value: boolean) => handleSubtitlesEnable(value)}
                />
              </div>
              <div className='template-step__subtitles-switch'>
                <span className='label'>Blurred background</span>
                <Switch
                  className="ios-switch"
                  checked={isWithBlurredBackground}
                  onChange={(value: boolean) => handleBlurredBackgroundChange(value)}
                />
              </div>
            </div>
            {showBrandingScreen ?
              <div className='template-step__right-block template-step__branding'>
                <WatermarkBlock handleSelectNoneWatermark={handleSelectNoneWatermark} watermarkFeatureSlider={watermarkFeatureSlider} handleChooseWatermark={handleChooseWatermark} outsideLoading={loading} templateMode />
              </div>
              :
              <div className='template-step__right-block'>
                <Select
                  className="font-family-select"
                  classNamePrefix="font-family-select-prefix"
                  styles={{
                    singleValue: (baseStyles, state) => ({
                      ...baseStyles,
                      fontFamily: editorTemplate?.subtitles_settings?.fontFamily,
                    }),
                  }}
                  value={fontFamilyOptions?.options.find(el => el.value === editorTemplate?.subtitles_settings?.fontFamily)}
                  onChange={(result: any) => handleTemplateFieldChange('fontFamily', result.value)}
                  options={fontFamilyOptions?.options}
                  components={{ DropdownIndicator, Option }}
                  placeholder='Fonts'
                />
                <div className='template-step__right-block-content'>
                  <div className='template-step__animations'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 16 }}>
                      <p className="editor-font-design__section-title">Animations</p>
                      {!allowAllAnimations && <IconStarNoColor className="star-icon" />}
                    </div>
                    <div className="editor-grid" style={{ maxWidth: 220 }}>
                      <div
                        className='editor-grid__item'
                        style={{
                          ...styles.box,
                          ...(animationType === 'none' ? styles.focusedBox : {}),
                        }}
                        onMouseEnter={() => onAnimationHoverEnter('none')}
                        onMouseLeave={() => onAnimationHoverLeave('none')}
                        onClick={() => handleClick('none')}
                      >
                        <div>
                          <span style={{ height: 'auto' }}>None</span>
                        </div>
                      </div>
                      {boxData.map((box, index) => (
                        <div
                          key={index}
                          className='editor-grid__item'
                          style={{
                            ...styles.box,
                            ...(animationType === box.animationName ? styles.focusedBox : {}),
                            ...(box.isActive === false ? styles.inactiveBox : {}), // Apply inactive style if isActive is false
                          }}
                          onMouseEnter={() => onAnimationHoverEnter(box.animationName)}
                          onMouseLeave={() => onAnimationHoverLeave(box.animationName)}
                          onClick={() => handleClick(box.animationName)}
                        >
                          <div
                            id={box.animationName} // Unique id for each element
                          >
                            <span style={{ height: 'auto' }}>{box.animationName}</span>
                          </div>
                          {box.isActive === false && (
                            <div style={styles.comingSoonText}>Coming Soon</div>
                          )}
                          {box.isActive === true && !allowAllAnimations && allowedCaptionsList?.indexOf(box.animationName) === -1 && (
                            <div style={{ ...styles.comingSoonText, color: '#ababc0' }}>Pro feature</div>
                          )}
                          {animationType === box.animationName && (
                            <div style={styles.smallText}>Animated</div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div style={{ flex: 'unset', background: 'transparent' }} className="editor-font-design">
                    <div className="editor-font-design__section">
                      <div className="editor-font-design__section-content">
                        <p className="editor-font-design__section-title">Font Styling</p>
                        <div className="editor-font-design__buttons">
                          <div onClick={handleFontWeightChange} className={`editor-font-design__button ${isBoldFont && 'active'}`} style={{ fontWeight: 700 }}>B</div>
                          <div onClick={handleTransformChange} className={`editor-font-design__button ${isUppercaseFont && 'active'}`}>AA</div>
                          <Select
                            className="font-size-select"
                            classNamePrefix="font-size-select-prefix"
                            value={fontSizeOptions.options.find(el => el.value === editorTemplate?.subtitles_settings?.fontSize)}
                            onChange={({ value }: any) => handleFontSizeChange(value)}
                            options={fontSizeOptions.options}
                            components={{ DropdownIndicator }}
                            placeholder='Fonts'
                          />
                          <div ref={colorPickerRef} className="editor-font-design__color-button">
                            <div onClick={handleOpenColorPicker} className="editor-font-design__button">
                              <ColorWheelIcon />
                            </div>
                            {openColorPicker &&
                              <div className="editor-font-design__font-colorpicker">
                                <SketchPicker color={chosenColor} onChangeComplete={handleChooseColor} />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="editor-font-design__section">
                      <div className="editor-font-design__section-content">
                        <p className="editor-font-design__section-title">Stroke</p>
                        <div className="editor-font-design__buttons">
                          <div onClick={() => handleStrokeChange('None')} className={`editor-font-design__button ${editorTemplate?.subtitles_settings?.textShadow === 'None' && 'active'}`}><BlockIcon /></div>
                          <div onClick={() => handleStrokeChange('Medium')} className={`editor-font-design__button ${editorTemplate?.subtitles_settings?.textShadow === 'Medium' && 'active'}`}>M</div>
                          <div onClick={() => handleStrokeChange('Heavy')} className={`editor-font-design__button ${editorTemplate?.subtitles_settings?.textShadow === 'Heavy' && 'active'}`}>L</div>
                          <div ref={strokeColorPickerRef} className="editor-font-design__color-button">
                            <div onClick={handleOpenStrokeColorPicker} className="editor-font-design__button">
                              <ColorWheelIcon />
                            </div>
                            {openStrokeColorPicker &&
                              <div className="editor-font-design__stroke-colorpicker">
                                <SketchPicker color={chosenStrokeColor} onChangeComplete={handleChooseStrokeColor} />
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="editor-font-design__section" style={{ borderBottom: 'none' }}>
                      <div className="editor-font-design__section-row">
                        <div className="editor-font-design__section-column">
                          <p className="editor-font-design__section-title">Caption Position</p>
                          <div onClick={() => handleChooseAlignment('Top')} className={`editor-font-design__column-button editor-font-design__button ${editorTemplate?.subtitles_settings?.alignItems === 'Top' && 'active'}`}>Top</div>
                          <div onClick={() => handleChooseAlignment('Middle')} className={`editor-font-design__column-button editor-font-design__button ${editorTemplate?.subtitles_settings?.alignItems === 'Middle' && 'active'}`}>Middle</div>
                          <div onClick={() => handleChooseAlignment('Bottom')} className={`editor-font-design__column-button editor-font-design__button ${editorTemplate?.subtitles_settings?.alignItems === 'Bottom' && 'active'}`}>Bottom</div>
                        </div>
                        <div className="editor-font-design__section-column">
                          <p className="editor-font-design__section-title">Caption Grouping</p>
                          <div onClick={() => handleChooseCaptionGrouping(true)} style={{ width: 112 }} className={`editor-font-design__column-button editor-font-design__button ${isGroupLevel && 'active'}`}>Group Level</div>
                          <div onClick={() => handleChooseCaptionGrouping(false)} style={{ width: 112 }} className={`editor-font-design__column-button editor-font-design__button ${!isGroupLevel && 'active'}`}>Word Level</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className='template-step__bottom-buttons'>
              {!showBrandingScreen && <div className='template-step__reset-btn' onClick={handleResetToDefault}>Choose a Default</div>}
              {showBrandingScreen ?
                <div className='template-step__reset-btn' onClick={handleBackToStyling}>
                  <BackIcon />
                  <span>Styling</span>
                </div>
                :
                <div className={`template-step__reset-btn branding-btn ${getImageGenerationEnabledForUser(userContext?.user) ? '' : 'starred-offset'}`} onClick={handleShowBrandingScreen}>
                  <BrushIcon />
                  <span>Branding</span>
                </div>
              }
            </div>
          </div>
        }
        <div>
          <div className="title-wrapper">
            <div className="section-title">Template styles</div>
            {/* <div style={{ maxWidth: 300 }} className="section-subtitle">Auto-edited clips will be created using your template</div> */}
          </div >
          <div className="style-section">
            <div
              style={{
                height: 484,
                width: 260,
                minWidth: 260,
                cursor: 'default'
              }}
              className={`style-card active`}
            >
              <div style={getStylesForTemplateCardWrapper()} className="style-card__custom-content">
                {watermarkFeatureSlider && watermarkFeatureSlider.imageUrl && <img style={getStylePreviewWatermark()} src={watermarkFeatureSlider.imageUrl} />}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <span style={getStylesForTemplateCardText()}
                  className={editorTemplate?.subtitles_settings?.captions_animation_type + '-infinite'}
                >
                  {/* {editorTemplate?.subtitles_settings?.wordLevel === 'true' ?
                    <span className={editorTemplate?.subtitles_settings?.captions_animation_type + '-infinite'}>Word</span>
                    :
                    <>
                      <span className={editorTemplate?.subtitles_settings?.captions_animation_type + '-infinite'}>Some</span>
                      <span style={{ animationDelay: '3s' }} className={editorTemplate?.subtitles_settings?.captions_animation_type + '-infinite'}>Words</span>
                    </>
                  } */}
                  {editorTemplate?.subtitles_settings?.wordLevel === 'true' ? 'Word' : 'Some Words'}
                </span>
              </div>

            </div>
          </div>
        </div >

      </div >
    )
  }
  else {
    return (
      <div className="block-wrapper" style={{ marginBottom: 30 }}>
        <div className="title-wrapper">
          <div className="section-title">Choose your style</div>
          <div className="section-subtitle">Select the style of captions you would like for your video editing.</div>
        </div>
        <div className="style-section">
          {stylePresets.map((preset, index) => (
            <div
              key={preset.id}
              onClick={() => handleSelectStylePreset(preset)}
              className={`style-card ${selectedStylePreset && selectedStylePreset.id === preset.id && 'active'}`}
            >
              {checkProBlockedStylePreset(preset) && !preset.availableToAll && <IconStarNoColor className="star-icon" />}
              <div className="style-card__content">
                <img src={gifUrls[index]} alt={`GIF ${index + 1}`} />
              </div>
              <div className="style-card__button">{preset.name}</div>
            </div>
          ))}
        </div>
        {!isMobile && <div style={{ marginTop: 15 }} className='template-step__reset-btn' onClick={handleShowDetailedTemplate}>Edit template</div>}
      </div >
    )
  }
};